import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { IProject } from '@js-elec/js-elec-types';
import * as go from "gojs";
import { DiagramComponent } from "gojs-angular";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PrintPreviewComponent } from "src/app/editor/components/print-preview.component";
import { FolioLayersComponent } from "src/app/folio/components/folio-layers.component";
import { FolioPropertiesComponent } from "src/app/folio/components/folio-properties.component";
import { WallThicknessEnum } from "src/app/folio/folio.constants";
import { FolioService, IOpenedFolio } from "src/app/folio/folio.service";
import { ProjectService } from "src/app/project/project.service";
import { NotificationService } from "src/app/shared/services/notify.service";
import { environment } from "src/environments/environment";
import { findDPI } from "../../shared/screen-tools";
import getsetFunctions from "../inspector/getset-functions";
import {
  DiagramLayersEnum,
  DiagramUnitsConversionEnum,
  LinkTypesEnum,
  bottomSystemLayers,
  topSystemLayers
} from "../models/elements";
import { Floorplan } from "./floorplan-diagram";


@Component({
  selector: "floor-planner",
  templateUrl: "floor-planner.component.html",
  styleUrls: ["floor-planner.component.scss"],
})
export class FloorPlannerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() closeFolio = new EventEmitter<IOpenedFolio>();
  @Input() openedFolio!: IOpenedFolio;

  @ViewChild("myDiagram", { static: true }) public myDiagramComponent!: DiagramComponent;

  model: any;

  diag!: Floorplan;
  diagReady!: Observable<Floorplan>;

  skipDiagramUpdate = false;
  diagramDivClassName = "myDiagramDiv";

  initialized = false;

  state!: any;


  destroy$ = new Subject();

  constructor(
    private projectSvc: ProjectService,
    private folioSvc: FolioService,
    private modalSvc: BsModalService,
    private notifySvc: NotificationService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const { nodeDataArray, modelData, linkDataArray } = this.openedFolio.folio;
    this.state = go.Model.fromJson({
      nodeDataArray,
      modelData,
      linkDataArray,
      class: "GraphLinksModel",
      copiesKey: true,
    }) as go.GraphLinksModel;
  }

  initDiagram(): go.Diagram {

    const $ = go.GraphObject.make;
    go.Diagram.licenseKey = environment.goLicense;

    this.diag = $(Floorplan, {
      "undoManager.isEnabled": true,
      // model: $(go.GraphLinksModel,
      //   {
      //     nodeKeyProperty: 'id',
      //     linkToPortIdProperty: 'toPort',
      //     linkFromPortIdProperty: 'fromPort',
      //     linkKeyProperty: 'key' // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
      //   }
      // )
    });

    // this.diag.commandHandler.archetypeGroupData = { key: 'Group', isGroup: true };

    // const makePort = function (id: string, spot: go.Spot) {
    //   return $(go.Shape, 'Circle',
    //     {
    //       opacity: .5,
    //       fill: 'gray', strokeWidth: 0, desiredSize: new go.Size(8, 8),
    //       portId: id, alignment: spot,
    //       fromLinkable: true, toLinkable: true
    //     }
    //   );
    // }

    // define the Node template
    // this.diag.nodeTemplate =
    //   $(go.Node, 'Spot',
    //     {
    //       contextMenu:
    //         $('ContextMenu',
    //           $('ContextMenuButton',
    //             $(go.TextBlock, 'Group'),
    //             { click: function (e, obj) { e.diagram.commandHandler.groupSelection(); } },
    //             new go.Binding('visible', '', function (o) {
    //               return o.diagram.selection.count > 1;
    //             }).ofObject())
    //         )
    //     },
    //     new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
    //     $(go.Panel, 'Auto',
    //       $(go.Shape, 'RoundedRectangle', { stroke: null },
    //         new go.Binding('fill', 'color', (c, panel) => {

    //           return c;
    //         })
    //       ),
    //       $(go.TextBlock, { margin: 8, editable: true },
    //         new go.Binding('text').makeTwoWay())
    //     ),
    //     // Ports
    //     makePort('t', go.Spot.TopCenter),
    //     makePort('l', go.Spot.Left),
    //     makePort('r', go.Spot.Right),
    //     makePort('b', go.Spot.BottomCenter)
    //   );

    // const self = this;



    // self.isInitialized.emit(this.diag);

    /*
  ngAfterViewInit() {
  this.diagReady = combineLatest([of(true), this.diag]).pipe(
    filter((res) => res[0] === true && res[1] !== null),
    delay(0),
    map((res) => res[1])
  );
  }*/

    return this.diag;
  }

  public ngAfterViewInit() {
    this.diag = this.myDiagramComponent.diagram as Floorplan;
    this.cdr.detectChanges(); // IMPORTANT: without this, Angular will throw ExpressionChangedAfterItHasBeenCheckedError (dev mode only)
    const $ = go.GraphObject.make;

    const self = this;
    this.myDiagramComponent.diagram.addDiagramListener("ExternalObjectsDropped", function (e) {
      const fp: Floorplan = e.diagram as Floorplan;
      fp.selection.iterator.each(function (node) {
        if (node.category === "ground") {
          self.folioSvc.lockGrounds$.next(true)
        }
      })
    });

    this.openedFolio.diagram = this.myDiagramComponent.diagram as Floorplan;

    // layers
    const forelayer = this.myDiagramComponent.diagram.findLayer(
      DiagramLayersEnum[DiagramLayersEnum.Foreground]
    );
    for (const item in DiagramLayersEnum) {
      if (!isNaN(Number(item))) {
        if (
          Number(item) > bottomSystemLayers &&
          Number(item) < topSystemLayers
        )
          forelayer && this.myDiagramComponent.diagram.addLayerBefore(
            $(go.Layer, { name: DiagramLayersEnum[item] }),
            forelayer
          );
      }
    }

    // this.diag.enableWallBuilding();
    this.myDiagramComponent.diagram.addDiagramListener("InitialLayoutCompleted", (e) => {
      const fp: Floorplan = e.diagram as Floorplan;

      // updateWalls
      fp.nodes.iterator.each((n) => {
        if (n.category === "WallGroup") {
          fp.updateWall(n as go.Group);
        }

        if (n.category === "RoomNode") {
          fp.updateRoom(n);
        }
      });

      fp.updateAllTargetBindings()

      getsetFunctions.setGridSize(fp, fp.model.modelData.gridSize);

      // fit size
      // this.diag.div.parentElement.style.display = "block";
      // const containerWidth = this.diag.div.parentElement.offsetWidth;
      // const containerHeight = this.diag.div.parentElement.parentElement
      //   .offsetHeight;
      // this.diag.div.style.width = `${containerWidth - 5}px`;
      // this.diag.div.style.height = `${containerHeight - 20}px`;
      // this.diag.requestUpdate();

      if (!this.initialized) {
        this.folioSvc.wallModeActivated$
          .pipe(takeUntil(this.destroy$))
          .subscribe((wallMode: WallThicknessEnum | undefined) => {
            if (wallMode === WallThicknessEnum.scale) {
              (this.myDiagramComponent.diagram as Floorplan).enableWallBuilding(wallMode, true);
            } else if (wallMode === WallThicknessEnum.divider) {
              (this.myDiagramComponent.diagram as Floorplan).enableDividerBuilding();
            } else if (wallMode === undefined) {
              (this.myDiagramComponent.diagram as Floorplan).disableWallBuilding();
            }
            else {
              (this.myDiagramComponent.diagram as Floorplan).enableWallBuilding(wallMode);
            }
          });

        this.folioSvc.connectorModeActivated$
          .pipe(takeUntil(this.destroy$))
          .subscribe((connectorMode: LinkTypesEnum | undefined) => {
            (this.myDiagramComponent.diagram as Floorplan).linkType = connectorMode ?? LinkTypesEnum.conduit;
          });

        setTimeout(() => {
          this.folioSvc.lockGrounds$
            .pipe(takeUntil(this.destroy$))
            .subscribe(lock => (this.myDiagramComponent.diagram as Floorplan).FreezeGrounds(lock))
        }, 600)
      }
      this.initialized = true

    });

  }

  public initDiagram2(): go.Diagram {

    const $ = go.GraphObject.make;
    const dia = $(go.Diagram, {
      'undoManager.isEnabled': true,
      // 'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
      model: $(go.GraphLinksModel,
        {
          nodeKeyProperty: 'id',
          linkToPortIdProperty: 'toPort',
          linkFromPortIdProperty: 'fromPort',
          linkKeyProperty: 'key' // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
        }
      )
    });

    dia.commandHandler.archetypeGroupData = { key: 'Group', isGroup: true };

    const makePort = function (id: string, spot: go.Spot) {
      return $(go.Shape, 'Circle',
        {
          opacity: .5,
          fill: 'gray', strokeWidth: 0, desiredSize: new go.Size(8, 8),
          portId: id, alignment: spot,
          fromLinkable: true, toLinkable: true
        }
      );
    }

    // define the Node template
    dia.nodeTemplate =
      $(go.Node, 'Spot',
        {
          contextMenu:
            $('ContextMenu',
              $('ContextMenuButton',
                $(go.TextBlock, 'Group'),
                { click: function (e, obj) { e.diagram.commandHandler.groupSelection(); } },
                new go.Binding('visible', '', function (o) {
                  return o.diagram.selection.count > 1;
                }).ofObject())
            )
        },
        new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
        $(go.Panel, 'Auto',
          $(go.Shape, 'RoundedRectangle', { stroke: null },
            new go.Binding('fill', 'color', (c, panel) => {

              return c;
            })
          ),
          $(go.TextBlock, { margin: 8, editable: true },
            new go.Binding('text').makeTwoWay())
        ),
        // Ports
        makePort('t', go.Spot.TopCenter),
        makePort('l', go.Spot.Left),
        makePort('r', go.Spot.Right),
        makePort('b', go.Spot.BottomCenter)
      );

    return dia;
  }


  closeFolioClick() {
    this.closeFolio.emit(this.openedFolio);
  }

  saveFolio() {
    const model: any = JSON.parse(this.diag.model.toJson());
    this.openedFolio.folio.nodeDataArray = model.nodeDataArray;
    this.openedFolio.folio.linkDataArray = model.linkDataArray;
    this.openedFolio.folio.modelData = model.modelData;
    this.folioSvc
      .updateFolio(
        this.projectSvc.currentProject.value as IProject,
        this.openedFolio.folio.id as string,
        this.openedFolio.folio
      )
      .subscribe(() =>
        this.notifySvc.notification("success", "Folio enregistré")
      );
  }

  downloadFolio() {
    function download(content: any, fileName: string = "export.json", contentType: any) {
      const a = document.createElement("a");
      const file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
      a.remove();
    }
    download(
      // (this.diag as Floorplan).model.toJson(),
      btoa((this.diag as Floorplan).model.toJson()),
      this.projectSvc.currentProject.value?.name + '_' + this.openedFolio.folio.name + ".json",
      "text/plain"
    );
  }

  setupLayers() {
    console.log('first:' + (this.openedFolio.diagram as Floorplan).model.modelData.elementSize);

    this.modalSvc.show(FolioLayersComponent, {
      class: "right-modal modal-sm",
      initialState: {
        project: this.projectSvc.currentProject.value,
        openedFolio: this.openedFolio,
      },
    });


  }

  setupProperties() {
    this.modalSvc.show(FolioPropertiesComponent, {
      class: "",
      initialState: {
        project: this.projectSvc.currentProject.value,
        openedFolio: this.openedFolio,
      },
    });
  }

  printFolio() {
    const bounds: go.Rect = this.openedFolio.diagram?.documentBounds as go.Rect;

    const resDpi = findDPI();
    const screenRes = resDpi / 2.54; // inch to cm
    const unitRatio =
      (DiagramUnitsConversionEnum[
        this.openedFolio.diagram?.model.modelData.units
      ] as any) / DiagramUnitsConversionEnum.cm;
    const scale =
      screenRes /
      (this.openedFolio.diagram?.model.modelData.unitsConversionFactor *
        unitRatio);

    const p = bounds.position;
    const img = this.openedFolio.diagram?.makeImage({
      scale: 1,
      position: new go.Point(p.x, p.y),
      size: new go.Size(bounds.width, bounds.height),
      showGrid: true,
      type: "image/png",
      returnType: "string",
    });

    // const doc = new jsPDF({
    //   orientation: "portrait",
    //   unit: "cm",
    //   format: "a4",
    //   // compress: true
    // });

    // doc.addImage(
    //   img as string,
    //   "png",
    //   0,
    //   0,
    //   bounds.width / scale,
    //   bounds.height / scale
    // );

    var imgDiv = document.getElementById('myImages');
    if (img && imgDiv) {
      // img.className = 'images';
      // imgDiv.appendChild(img);
      // imgDiv.appendChild(document.createElement('br'));

      this.modalSvc.show(PrintPreviewComponent, { initialState: { folio: this.openedFolio }, class: 'full-modal' })
    }
    // doc.save(`folio - ${this.openedFolio.folio.name}.pdf`);
  }

  // When the diagram model changes, update app data to reflect those changes. Be sure to use immer's "produce" function to preserve immutability
  diagramModelChange(changes: go.IncrementalData) {
    // return;
    // if (!changes) return;
    // const appComp = this;
    // this.state = produce(this.state, (draft: any) => {
    //   // set skipsDiagramUpdate: true since GoJS already has this update
    //   // this way, we don't log an unneeded transaction in the Diagram's undoManager history
    //   draft.skipsDiagramUpdate = true;
    //   draft.diagramNodeData = DataSyncService.syncNodeData(changes, draft.diagramNodeData);
    //   draft.diagramLinkData = DataSyncService.syncLinkData(changes, draft.diagramLinkData);
    //   draft.diagramModelData = DataSyncService.syncModelData(changes, draft.diagramModelData);
    //   // If one of the modified nodes was the selected node used by the inspector, update the inspector selectedNodeData object
    //   const modifiedNodeDatas = changes.modifiedNodeData;
    //   if (modifiedNodeDatas && draft.selectedNodeData) {
    //     for (let i = 0; i < modifiedNodeDatas.length; i++) {
    //       const mn = modifiedNodeDatas[i];
    //       const nodeKeyProperty = appComp.myDiagramComponent.diagram.model.nodeKeyProperty as string;
    //       if (mn[nodeKeyProperty] === draft.selectedNodeData[nodeKeyProperty]) {
    //         draft.selectedNodeData = mn;
    //       }
    //     }
    //   }
    // });
  };
  // diagramModelChange(changes: go.IncrementalData) {
  //   // return;
  //   // when setting state here, be sure to set skipsDiagramUpdate: true since GoJS already has this update
  //   // (since this is a GoJS model changed listener event function)
  //   // this way, we don't log an unneeded transaction in the Diagram's undoManager history
  //   this.skipDiagramUpdate = true;
  //   this.openedFolio.folio.nodeDataArray = DataSyncService.syncNodeData(
  //     changes,
  //     this.model.nodeDataArray
  //   );
  //   this.openedFolio.folio.linkDataArray = DataSyncService.syncLinkData(
  //     changes,
  //     this.model.linkDataArray
  //   );
  //   this.openedFolio.folio.modelData = DataSyncService.syncModelData(
  //     changes,
  //     this.model.modelData
  //   );
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
