import * as go from "gojs";

export const roomColors = [
  "#ffff00",
  "#ffd416",
  "#ffae31",
  "#f78c46",
  "#e97057",
  "#d85b67",
  "#c44e74",
  "#ae4a7d",
  "#964e80",
  "#7e577a",
  "#666269",
  "#4a704a",
  "#008000",
];

export enum LinkTypesEnum {
  "conduit" = 0,
  "circuit_cfort" = 10,
  "logical" = 12,
  "commande" = 15,
  "circuit_cfaible" = 20,
}

export enum GaineLengthsEnum {
  length1650 = 'length1650',
  length1150 = 'length1150',
  length1050 = 'length1050',
  length950 = 'length950',
  length850 = 'length850',
  length750 = 'length750',
}

export enum WallTypesEnum {
  "mur_porteur" = 0,
  "mur_interieur" = 10,
  "cloison_interieure" = 20,
}

export enum DiagramUnitsAbbreviations {
  m = "m",
  cm = "cm",
  mm = "mm",
  in = "in",
  ft = "ft",
}

export enum ScalesEnum {
  cinquantieme = 50,
  centieme = 100,
}

export enum DiagramUnitsConversionEnum {
  m = 1,
  cm = 0.01,
  mm = 0.001,
  in = 0.393701,
  ft = 0.0328084,
}

export type ElementShapes = "Rectangle";
export enum DiagramLayersEnum {
  "Grid" = 0,
  "Background" = 5,
  "Ground" = 10,
  "Scale" = 15,
  "Walls" = 20,
  "Room" = 30,
  "Links" = 75,
  "Furnitures" = 100,
  "Commands" = 150,
  "Lamps" = 180,
  "WallOutlet" = 200,
  "Divers" = 300,
  "Utilities" = 400,
  "Comfort" = 500,
  "HomeAutomation" = 600,
  "Cartouche" = 700,
  "Default" = 1000,
  "Foreground" = 2000,
  "Adornment" = 5000,
  "Tool" = 10000,
}

export const layersDisabled = [
  DiagramLayersEnum.Scale,
  DiagramLayersEnum.Room,
  DiagramLayersEnum.Links,
  DiagramLayersEnum.Furnitures,
  DiagramLayersEnum.Commands,
  DiagramLayersEnum.Lamps,
  DiagramLayersEnum.Divers,
  DiagramLayersEnum.Utilities,
  DiagramLayersEnum.Comfort,
  DiagramLayersEnum.HomeAutomation,
  DiagramLayersEnum.Cartouche,
  DiagramLayersEnum.Default,
]

export const bottomSystemLayers: number = DiagramLayersEnum.Background;
export const topSystemLayers: number = DiagramLayersEnum.Foreground;

export interface BaseElement {
  key: string;
  symbolName: string;
  name: string;
  showName: boolean;
  category: string;
  type: string;
  layer: DiagramLayersEnum;
  color: string;
  fill: string;
  nature?: string;
  tableau?: string;
  shape?: ElementShapes;
  geo?: string;

  notes?: string;

  loc?: string;
  width: number;
  height: number;
  angle: number;
}

export interface RectangleElement {
  width: number;
  height: number;
}

export interface LabelElement {
  showLabel: string;
  text: string;
}

export interface TextureElement {
  texture: string;
  usesTexture: boolean;
  showTextureOptions: boolean;
  textures: string[];
}
