import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IFolio, IProject } from '@js-elec/js-elec-types';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Floorplan } from "../floorPlanner/diagram/floorplan-diagram";
import { LinkTypesEnum } from "../floorPlanner/models/elements";
import { getGaineMaxLength } from "../floorPlanner/tools";
import { NotificationService } from "../shared/services/notify.service";
import { defaultFolio, WallThicknessEnum } from "./folio.constants";

export interface IOpenedFolio {
  folio: IFolio;
  diagram?: Floorplan;
}

@Injectable({
  providedIn: "root",
})
export class FolioService {
  openedFolios: IOpenedFolio[] = [];
  selectedFolio$ = new BehaviorSubject<IOpenedFolio | null>(null);
  openFolio$ = new Subject<IFolio>();
  closeFolio$ = new Subject<number>();
  wallModeActivated$ = new BehaviorSubject<WallThicknessEnum | undefined>(undefined);
  connectorModeActivated$ = new BehaviorSubject<LinkTypesEnum | undefined>(LinkTypesEnum.conduit);
  lockGrounds$ = new BehaviorSubject<boolean>(true);
  showMeasures$ = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private notifySvc: NotificationService
  ) {
    this.openFolio$.subscribe((folio) => {
      const doublon = this.openedFolios.find((f) => f.folio.id === folio.id);
      if (doublon) {
        this.selectedFolio$.next(doublon);
        return;
      }
      const openedFolio = { folio };
      this.selectedFolio$.next(openedFolio);
      this.openedFolios.push(openedFolio);
    });
    this.closeFolio$.subscribe((index) => {
      if (index >= 0 && index < this.openedFolios.length) {
        this.openedFolios.splice(index, 1);
        if (this.openedFolios.length > 1) {
          this.selectedFolio$.next(this.openedFolios[Math.max(0, index - 1)]);
        } else {
          this.selectedFolio$.next(null);
        }
      }
    });

    this.showMeasures$.subscribe((showMeasures: boolean) => {
      if (showMeasures) {
        this.openedFolios.forEach(folio => {
          // folio.diagram?.dimensionLinks.clear();
          // folio.diagram?.wall
          // folio.diagram?.updateWallDimensions();
        })
      }
    })
  }

  addOpenedFolio(folio: IFolio) {
    this.openFolio$.next(folio);
  }

  removeOpenedFolio(index: number) {
    this.closeFolio$.next(index);
  }

  closeAllFolios() {
    this.openedFolios = [];
    this.selectedFolio$.next(null)
  }

  findOpenedFolio(folio: IFolio) {
    return this.openedFolios.find((f) => f.folio.id === folio.id);
  }

  setDiagramFolio(folio: IFolio, diagram: Floorplan) {
    const openedFolio = this.findOpenedFolio(folio);
    if (openedFolio) {
      openedFolio.diagram = diagram;
    }
  }

  addFolio(project: IProject, folio: IFolio): Observable<IProject> {
    const doublon = project.folios.find(f => f.name === folio.name)
    if (doublon) {
      folio.name = folio.name + " 2";
    }
    return this.httpClient
      .post<IProject>(
        `${(environment as any).apiHost}/folio/${project.id}`,
        Object.assign(JSON.parse(JSON.stringify(defaultFolio)), folio)
      )
      .pipe(
        catchError((err) => {
          this.notifySvc.notification("error", `Une erreur s'est produite`);
          throw err;
        }),
        tap((projectUpdated) => Object.assign(project, projectUpdated)),
        tap((projectUpdated) => this.openFolio$.next(projectUpdated.folios.find(f => f.name === folio.name)))
      );
  }

  updateFolio(
    project: IProject,
    folioId: string,
    folio: IFolio
  ): Observable<IProject> {
    const doublon = project.folios.find(f => f.name === folio.name && f.id !== folioId)
    if (doublon) {
      folio.name = folio.name + " 2";
    }

    return this.httpClient
      .put<IProject>(
        `${(environment as any).apiHost}/folio/${project.id}/${folioId}`,
        folio
      )
      .pipe(
        catchError((err) => {
          this.notifySvc.notification("error", "Une erreur s'est produite");
          console.log(err);
          throw err;
        })
      );
  }

  deleteFolio(project: IProject, folio: IFolio) {
    const folioIndex = project.folios.indexOf(folio);
    return this.httpClient
      .delete<IProject>(
        `${(environment as any).apiHost}/folio/${project.id}/${project.folios[folioIndex].id
        }`
      )
      .pipe(
        catchError((err) => {
          this.notifySvc.notification("error", `Une erreur s'est produite`);
          throw err;
        }),
        tap(() => project.folios.splice(folioIndex, 1))
      );
  }

  lockGrounds(lock: boolean) {
    this.lockGrounds$.next(lock)
  }

  isGroundLocked() {
    return this.lockGrounds$.value
  }

  showMeasures(showMeasures: boolean) {
    this.showMeasures$.next(showMeasures)
  }

  shouldDisplayMeasures() {
    return this.showMeasures$.value;
  }

  getMaterials(folio: IFolio) {
    return folio.nodeDataArray
      .filter(nodeData => nodeData.category === 'element')
      .map(nodeData => ({
        identifiant: nodeData.key,
        etiquette: nodeData.name,
        type: nodeData.type,
        connected: nodeData.deviceConnected ? 'Connecté' : '-',
        tableau: nodeData.tableau,
        refMateriel: ""
      }))
  }

  getConduits(folio: IFolio) {
    const tree = this.buildTree(folio);
    const conduits = tree
      .filter(n => n.parent)
      .map(node => folio.linkDataArray.find(link =>
        (link.to === node.key && link.from === node.parent) ||
        (link.from === node.key && link.to === node.parent)))
      .filter(n => n !== undefined)
      .map(link => ({
        tenant: folio.nodeDataArray.find(node => node.key === link?.from)?.name || link?.from,
        aboutissant: folio.nodeDataArray.find(node => node.key === link?.to)?.name || link?.to,
        type: 'Conduit',
        longueur: link?.length,
        chutes: getGaineMaxLength(link?.linkType) !== undefined ? (getGaineMaxLength(link?.linkType) || 0) - link?.length : 'N/A'
      }))
    return conduits;
    // return folio.linkDataArray
    //   .filter(link => /(tableau)/i.test(link.to) || /(tableau)/i.test(link.from))
    //   .sort((link1, link2) => link1.key < link2.key ? 1 : -1)
    //   .map(link => ({
    //     tenant: folio.nodeDataArray.find(node => node.key === link.from)?.name || link.from,
    //     aboutissant: folio.nodeDataArray.find(node => node.key === link.to)?.name || link.to,
    //     type: 'Conduit',
    //     longueur: link.length
    //   }))
  }

  buildTree(data: { linkDataArray: any[], nodeDataArray: any[] }, tree: any[] = [], ancestors?: any[]) {

    if (!ancestors) {
      ancestors = data.nodeDataArray.filter(node => /tableau/i.test(node.key))
    }

    ancestors.forEach(parentNode => {
      // get links starting from parent node
      const links = data.linkDataArray
        // regular structure
        .filter(link => link.from === parentNode.key || link.to === parentNode.key)
        // filter not valid links
        .filter(link => link.from !== link.to && link.from && link.to)

      const aboutissantsKey = links.map(link => link.to === parentNode.key ? link.from : link.to);
      const aboutissants: any[] = data.nodeDataArray
        .filter(node => aboutissantsKey.indexOf(node.key) >= 0)
        // node not treated
        .filter(node => !tree.find(n => n.key === node.key))
        // make copy
        .map(node => ({ ...node }));

      tree.push(parentNode)
      aboutissants.forEach(ab => {
        ab.parent = parentNode.key;
        ab.length = 10;
        tree = this.buildTree(data, tree, [ab])
      });

    })

    return tree;
  }

  findComponentInTree(componentId: string, tree?: any[]) {
    if (!tree) {
      const selectedFolio = this.selectedFolio$.value as IOpenedFolio;
      if (selectedFolio.diagram) {
        const { linkDataArray, nodeDataArray } = selectedFolio.diagram.model as go.GraphLinksModel
        tree = this.buildTree({ linkDataArray, nodeDataArray })
      }
      else
        tree = []
    }

    const foundNode = tree.find(node => node.key === componentId);
    return foundNode;
  }
}
